<template>
  <app-panel
      event-name="openOrderTime"
      :hide-nav="!showModalElements"
      :hide-validate-button="!showModalElements"
      :title="$t('when_you_want_order')"
      :validate-button="$t('save')"
      :size="size"
      :is-loading="isLoading"
      @open="open"
      @validated="save"
      ref="orderTimeModal"
      class="order-time-modal"
  >

    <div class="closed" v-if="init">
      <div class="close-container">
        <i class="material-icons notranslate" @click="close()">close</i>
      </div>
      <img src="https://delicity.b-cdn.net/front/img/closed.png">


      <template v-if="merchant.activated && merchant.shifts.nextOpenHour">
        <h1 v-if="merchant.shifts.openToday">{{ $t('order_time.title_today', {hours: merchant.shifts.openDateAt})}}</h1>
        <h1 v-else>{{ $t('order_time.title', {hours: merchant.shifts.openDateAt})}}</h1>

        <template v-if="dateSlots.length > 0 && cart.type !== 'on_site_counter' && cart.type !== 'on_site'">
          <span v-if="merchant.shifts.openToday">{{ $t('order_time.reopen_today', {hours: merchant.shifts.openDateAt }) }}</span>
          <span v-else>{{ $t('order_time.reopen_at', {hours: merchant.shifts.openDateAt }) }}</span>
          <span>{{ $t('order_time.preorder_now') }}</span>
        </template>
        <template v-else>
          <span>{{ $t('order_time.preorder_not_available') }}</span>
          <span>{{ $t('order_time.preorder_not_available_2') }}</span>
        </template>


        <div class="closed-hours-selector" v-if="dateSlots.length > 0 && cart.type !== 'on_site_counter' && cart.type !== 'on_site'">

          <template v-if="date !== 'other'">
            <select class="form-select" v-model="date">
              <option :value="d.value" v-for="d in dateSlots">{{ d.today ? $t('system.today') : d.dateString }}</option>
              <option value="other">Autres dates</option>
            </select>
          </template>
          <template v-else>
            <ui-datepicker
                icon="events"
                picker-type="modal"
                placeholder="Choisir une date"
                :custom-formatter="pickerDateFormatter"
                :min-date="pickerDateMin"
                :max-date="pickerDateMax"
                :class="{'form-select-date-small-error': errors.find(e => e.error === 'datepicker')}"
                :start-of-week="1"
                :lang="$t('datepicker')"
                v-model="pickerDate"
            ></ui-datepicker>
          </template>




          <select class="form-select" v-model="hours" :disabled="hourSlots.length === 0">
            <option :value="h.value" v-for="h in hourSlots">{{h.label}}</option>
          </select>
        </div>

        <div class="action" v-if="dateSlots.length > 0 && cart.type !== 'on_site_counter' && cart.type !== 'on_site'">
          <ui-button color="primary" @click="initDateSelector" size="large" style="width: 200px">{{$t('cta.order')}}</ui-button>
        </div>
        <div class="action" v-else>
          <ui-button color="primary" style="width: 200px" @click="close()" size="large">{{$t('cta.ok')}}</ui-button>
        </div>

      </template>
      <template v-else>
        <h1>{{ $t('order_time.order_unavailable')}}</h1>
        <span>{{$t('order_time.order_closed')}}</span>

        <div class="action">
          <ui-button color="primary" style="width: 200px" @click="close()" size="large">{{$t('cta.ok')}}</ui-button>
        </div>
      </template>


    </div>

    <div class="order-time-container" v-if="!init">
      <div class="order-time-setup">

        <div class="time-setup-title">
          {{$t('order_type')}}
        </div>
        <div class="order-type-selector">
          <ui-radio name="type" v-model="orderType" v-if="merchant.options.deliveryEnabled" color="green" trueValue="delivery">{{$t('in_delivery')}}</ui-radio>
          <ui-radio name="type" v-model="orderType" v-if="merchant.options.pickupEnabled" color="green" trueValue="pickup">{{$t('in_pickup')}}</ui-radio>
        </div>

        <div class="time-setup-title">
          {{$t('order_config_time')}}
        </div>

        <div class="order-type">
          <ui-radio name="type" v-model="orderOrderType" trueValue="when_possible">
            <span>{{$t('when_possible')}}</span>

            <div class="time" v-if="!merchant.shifts.open && merchant.shifts.nextOpenHour">
              {{ $t('we_re_open_at', {date: merchant.shifts.nextOpenHour }) }}
            </div>
            <div class="time" v-if="merchant.shifts.open">
              {{ $t('order_time_now', {time: nowDuration}) }}
            </div>

          </ui-radio>
          <ui-radio name="type" v-model="orderOrderType" trueValue="schedule_for_later">
            <span>{{$t('schedule_for_later')}}</span>
          </ui-radio>
        </div>

        <div class="order-hours">

          <template v-if="date !== 'other'">
            <select class="form-select" v-model="date" :disabled="orderOrderType !== 'schedule_for_later'">
              <option :value="d.value" v-for="d in dateSlots">{{ d.today ? $t('system.today') : d.dateString }}</option>
              <option value="other">Autres dates</option>
            </select>
          </template>
          <template v-else>

            <ui-datepicker
                icon="events"
                picker-type="modal"
                placeholder="Choisir une date"
                class="form-control form-control-datetime-picker"
                :class="{'form-select-error': errors.find(e => e.error === 'datepicker')}"
                :custom-formatter="pickerDateFormatter"
                :min-date="pickerDateMin"
                :max-date="pickerDateMax"
                :start-of-week="1"
                :lang="$t('datepicker')"
                v-model="pickerDate"
            ></ui-datepicker>

          </template>

          <select class="form-select" v-model="hours" :disabled="orderOrderType !== 'schedule_for_later' || hourSlots.length === 0">
            <option :value="h.value" v-for="h in hourSlots">{{h.label}}</option>
          </select>
        </div>

        <div class="order-hour-error" v-if="errors.find(e => e.error === 'datepicker')">
          <i class="material-icons notranslate text-danger">error_outline</i>
          <small class="text-danger">{{errors.find(e => e.error === 'datepicker').message}}</small>
        </div>

      </div>
    </div>


  </app-panel>
</template>

<style lang="scss">

.order-hour-error
{
  padding: 10px 0;
  line-height: 1;
  display: flex;
  align-items: center;
  i
  {
    font-size: 1em;
    margin-right: 5px;
  }
  small
  {
    font-weight: 600;
  }
}

.closed-hours-selector
{
  display: flex;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  .form-select
  {
    min-width: 200px;
    border: 0;
    border-radius: 0;
    &:first-child
    {
      border-right: 1px solid #ccc;
    }
  }
}

.form-control-datetime-picker
{
  margin: 0;
  padding: 28px 20px;
  height: auto;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ui-datepicker__icon-wrapper
  {
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .ui-datepicker__display
  {
    border: 0;
  }
}

.form-select-error {
  box-shadow: 0 0 0 2px rgba(227, 28, 28, 0.4) !important;
  background-color: #fff1f1 !important;
}
.form-select-date-small-error {
  background-color: #ffeded !important;
}

</style>


<script>
import PanelTemplate from "@/desktopComponents/PanelTemplate";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  components: {
    'app-panel': PanelTemplate
  },
  data(){
    const maxDate = new Date();
    const minDate = new Date();
    maxDate.setDate(maxDate.getDate() + 60);
    minDate.setHours(0);
    minDate.setMinutes(0);

    return {
      isLoading: false,
      init: false,
      orderType: 'delivery',
      orderOrderType: 'when_possible',
      date: null,
      hours: null,
      error: null,
      errors: [],
      dateSlots: [],
      hourSlots: [],
      next: false,
      pickerDate: null,
      pickerDateMin: minDate,
      pickerDateMax: maxDate,
    }
  },
  methods: {
    ...mapActions(['setOrderType', 'setOrderTime', 'getHourSlots', 'getDateSlots']),
    endInit(){
      this.init = false;
    },
    initDateSelector(){
      this.orderOrderType = 'schedule_for_later';
      this.save();
    },
    pickerDateFormatter(date){
      return date.toLocaleDateString();
    },
    async open(payload){
      this.errors = [];
      this.error = null;
      if(payload && payload.initiated){
        this.endInit();
      }

      if(this.dateSlots.length === 0 || this.merchant.activated === false){
        this.init = true;
      }

      this.orderType = this.cart.type;
      this.next = false;

      if(this.cart.laterOrder){
        if(!this.dateSlots.find(d => d.value === this.cart.laterOrder.date)){
          this.pickerDate = new Date(this.cart.laterOrder.date);
          this.date = 'other';
        }
        else{
          this.date = this.cart.laterOrder.date;
        }
        this.hours = this.cart.laterOrder.hour;
      }

      if(payload && payload.next){
        this.orderOrderType = 'schedule_for_later';
        this.date = moment.unix(payload.next).format('YYYY-MM-DD');

        await this.loadHourSlot();
        this.next = moment.unix(payload.next);
        const hour = this.next.format('HH:mm');
        if(this.hourSlots.find(e => e.value === hour)){
          this.hours = hour;
        }
        else if(this.hourSlots.length > 0){
          this.hours = false;
          this.hourSlots.forEach((h) => {
            if(moment(this.date + ' ' + h.value ).unix() >= payload.next && !this.hours){
              this.hours = h.value;
              return;
            }
          })
        }
      }

    },
    close(){
      this.$refs.orderTimeModal.close()
    },
    loadDates(){
      return this.getDateSlots()
          .then((data) => {
            this.dateSlots = data.slots;
            if(this.dateSlots.length > 0 && !this.date){
              this.date = data.slots[0].value;
            }
            if(this.dateSlots.length === 0){
              this.init = true;
            }
          });
    },
    async save(){
      this.isLoading = true;

      if(this.orderType != this.cart.type){
        if(this.orderType === 'delivery' || this.orderType === 'pickup'){
          await this.setOrderType(this.orderType);
        }
      }

      if(this.hourSlots.length === 0 && this.orderOrderType !== 'when_possible'){
        this.errors.push({error: 'datepicker', message: "Les commandes sont fermées à cette date"});
        this.isLoading = false;
        this.hours = null;
        return;
      }

      if(!this.hours && this.orderOrderType !== 'when_possible'){
        this.errors.push({error: 'datepicker', message: "Pas d'horaire selectionné."});
        this.isLoading = false;
        return;
      }


      let date = this.date;
      if(date === 'other'){
        date = moment(this.pickerDate).format('YYYY-MM-DD');
      }
      setTimeout(() => {
        this.setOrderTime({time: this.orderOrderType, laterAtDate: date, laterAtHour: this.hours})
            .then(() => {
              this.isLoading = false;
              this.close();
            })
            .catch((error) => {
              this.isLoading = false
              this.error = error;
            });
      }, 200);

    },
    loadHourSlot(){
      let date = this.date;
      if(this.date === 'other'){
        this.hourSlots = [];
        date = moment(this.pickerDate).format('YYYY-MM-DD');
      }

      return this.getHourSlots({date:date, type:this.orderType})
          .then((slots) => {
            this.hourSlots = slots;
            if(this.hourSlots.length > 0 && !this.hours){
              this.hours = this.hourSlots[0].value;
            }
            if(this.hourSlots.length > 0 && this.hours){
              if(!this.hourSlots.find(e => e.value === this.hours)){
                this.hours = this.hourSlots[0].value;
              }
            }
            if(this.hourSlots.length === 0){
              this.errors.push({error: 'datepicker', message: "Les commandes sont fermées à cette date"});
            }
          })
          .catch((error) => {
            this.error = error;
          });
    }
  },
  computed: {
    ...mapGetters(['merchant', 'cart']),
    size(){
      return this.init ? 'semi' : 'semi';
    },
    showModalElements(){
      return this.init === false;
      this.open();
    },
    nowDuration(){
      if(this.cart.type === 'delivery' && this.cart.cartDelivery && this.cart.OrderProducts.length > 0 && this.cart.cartDelivery.duration.minDuration !== 0){
        return this.cart.cartDelivery.duration.minDuration + '-' + this.cart.cartDelivery.duration.maxDuration;
      }
      if(this.cart.type === 'delivery'){
        return this.merchant.estimatedTime;
      }
      if(this.cart.type === 'pickup'){
        return this.merchant.estimatedPreparationTime;
      }
    },
  },
  async mounted() {
    await this.loadDates();

    this.orderOrderType = this.cart.laterOrder !== null ? 'schedule_for_later' : 'when_possible';

    if(this.cart.laterOrder){
      if(!this.dateSlots.find(d => d.value === this.cart.laterOrder.date)){
        this.pickerDate = new Date(this.cart.laterOrder.date);
        this.date = 'other';
      }
      else{
        this.date = this.cart.laterOrder.date;
        this.hours = this.cart.laterOrder.hour;
      }
    }

    if(this.merchant && !this.merchant.shifts.open && this.cart.laterOrder === null && this.cart.type !== 'on_site'){
      setTimeout(() => {
        if(this.$route.name === 'merchant'){
          this.$root.$emit('openOrderTime');
        }
      }, 1500)
      this.time = 'schedule_for_later';
      this.init = true;
      if(!this.date){
        this.date = this.merchant.shifts.openAtDate;
      }
    }

  },
  watch: {
    date(){
      this.errors = [];
      this.loadHourSlot();
    },
    pickerDate(){
      this.errors = [];
      this.loadHourSlot();
    },
    orderType(){
      if(this.dateSlots.length > 0 && this.date){
        this.loadHourSlot();
      }
    }
  }
}
</script>
